import React from 'react'
import { connect } from 'react-redux'
import Head from 'next/head'
import withStorage from '../components/storage'
import Layout, { siteTitle } from '../components/layout'
import FormHandler from '../components/form-handler'
import UserNavBar from '../components/nav-bar'
import { loginUser } from '../lib/redux/actions'
import { RESET_PASSWORD_URL } from '../lib/env'
import { TEMP_ACCESS_TOKEN_KEY, ACCESS_TOKEN_KEY } from '../lib/constants'
import { getParams } from '../lib/functions'

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null
    }
  }

  get currentUser () {
    return this.props.currentUser
  }

  componentDidMount () {
    if (this.currentUser) {
      window.location = '/'
    }
  }

  onSubmit (values) {
    const { loginUser, storeKey, removeKey } = this.props

    removeKey(ACCESS_TOKEN_KEY)
    removeKey(TEMP_ACCESS_TOKEN_KEY)

    return loginUser(values)
      .then(response => {
        const params = getParams()
        let path = params.redirect || '/'

        if (response.data.otpRequired) {
          storeKey(TEMP_ACCESS_TOKEN_KEY, response.data.token)
          path = '/verify-login'
          if (params.redirect) {
            path += '?redirect=' + params.redirect
          }
        } else {
          storeKey(ACCESS_TOKEN_KEY, response.data.token)
        }

        window.location = path
      })
      .catch(error => {
        this.setState({ error: error.response.data.error })
      })
  }

  render () {
    const { handleSubmit, submitting, handleChange, values } = this.props
    const { error } = this.state

    return (
      <Layout>
        <Head>
          <title>Login - {siteTitle}</title>
        </Head>
        <UserNavBar />

        <div className='container py-5'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 col-lg-6'>
              <div className='card'>
                <div className='card-body'>
                  <h1 className='card-title'>Login</h1>

                  <form
                    onSubmit={handleSubmit(values => this.onSubmit(values))}
                  >
                    <div className='form-group'>
                      <label>Email</label>
                      <input
                        type='email'
                        name='email'
                        value={values.email || ''}
                        onChange={handleChange}
                        placeholder='Email'
                        className='form-control'
                        required
                        autoFocus
                      />
                    </div>
                    <div className='form-group mb-4'>
                      <label>Password</label>
                      <input
                        type='password'
                        name='password'
                        value={values.password || ''}
                        onChange={handleChange}
                        placeholder='Password'
                        className='form-control'
                        required
                      />
                      <a
                        href={RESET_PASSWORD_URL}
                        className='form-text small text-right'
                      >
                        Forgot your password?
                      </a>
                    </div>
                    {error && <p className='text-danger'>{error}</p>}
                    <button
                      type='submit'
                      className='btn btn-primary btn-block'
                      disabled={submitting}
                    >
                      {submitting ? 'Logging you in...' : 'Log in'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

function mapStateToProps (state) {
  const { currentUser } = state

  return {
    currentUser
  }
}

const mapDispatchToProps = {
  loginUser
}
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormHandler(Login))

export default withStorage(connected)
