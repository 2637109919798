import React from 'react'
import { useRouter } from 'next/router'

export default function ActiveLink (props) {
  const router = useRouter()
  const { href, children } = props
  const activeClass = href === router.pathname ? 'active' : ''

  return (
    <a className={`flex-fill ${activeClass}`} href={href}>{children}</a>
  )
}
