import React from 'react'
import Head from 'next/head'

export const siteTitle = 'Program Portal'

class Layout extends React.Component {
  render () {
    const { children } = this.props

    return (
      <div>
        <Head>
          <link rel='stylesheet' href='//fonts.googleapis.com/css?family=Lato:300,400,500,900' async />
          <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
          <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
          <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        </Head>
        {children}
      </div>
    )
  }
}

export default Layout
