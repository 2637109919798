import React from 'react'
import { connect } from 'react-redux'
import withStorage from './storage'
import Dropdown from './dropdown'
import SearchBar from './search-bar'
import Restricted from './restricted'
import ActiveLink from './active-link'
import { getAnalysts } from '../lib/redux/actions'

class UserNavBar extends React.Component {
  componentDidMount () {
    const { getAnalysts, currentUser } = this.props

    currentUser && getAnalysts()
  }

  render () {
    const { currentUser, left, right, backUrl, showSearch, mainNav } =
      this.props
    const className = this.props.className || 'shadow-sm mb-5'

    return (
      <div className={className}>
        <nav className='main-page-nav navbar bg-white border-bottom navbar-light'>
          <div className='container'>
            <div className='navbar-brand'>
              {backUrl && (
                <a className='mr-4' href={backUrl}>
                  <img src='/arrow-back.svg' height='16' alt='Back' />
                </a>
              )}
              <a href='/tasks' className='mr-3'>
                <img
                  src='/logo.svg'
                  height='30'
                  className='align-top'
                  alt='Invoy'
                />
              </a>
              {left || 'Program Portal'}
            </div>

            {currentUser && (
              <div className='d-flex flex-fill justify-content-end'>
                {showSearch && <SearchBar />}
                {right || (
                  <Dropdown>
                    {({ Toggle, Menu, Item }) => (
                      <>
                        <Toggle className='btn' nav caret>
                          Hi {currentUser.firstName}
                        </Toggle>
                        <Menu right>
                          <Item tag='a' href='/v2/dashboard'>
                            New Portal
                          </Item>

                          <Restricted policy='program::management'>
                            <Item tag='a' href='/teams'>
                              Teams
                            </Item>
                          </Restricted>

                          <Item tag='a' href='/logout'>
                            Log out
                          </Item>
                        </Menu>
                      </>
                    )}
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        </nav>

        {currentUser && mainNav && (
          <div className='bg-white'>
            <nav className='container d-flex justify-content-center top-nav'>
              <ActiveLink href='/tasks'>TASKS</ActiveLink>
              <ActiveLink href='/clients'>CLIENTS</ActiveLink>
              <ActiveLink href='/calendar'>CALENDAR</ActiveLink>
              <ActiveLink href='/metrics'>METRICS</ActiveLink>
            </nav>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps (state) {
  const { currentUser } = state

  return {
    currentUser
  }
}

const mapDispatchToProps = {
  getAnalysts
}
const connected = connect(mapStateToProps, mapDispatchToProps)(UserNavBar)

export default withStorage(connected)
