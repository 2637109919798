import React from 'react'
import store from '../lib/redux/store'
import Authenticated from './authenticated'

export function allow (policyName) {
  const currentUser = store.getState().currentUser

  if (!policyName) return true
  if (!currentUser) {
    console.warn('Tried to check access policy on null currentUser')
    return
  }

  const policy = store.getState().accessPolicies[policyName]

  if (!policy) return false

  return policy.roles.some(role => {
    return currentUser.roles.indexOf(role) > -1
  })
}

const Restricted = React.memo(({ policy, exclude, children }) => {
  return (
    <Authenticated>
      {() => {
        if (exclude && !allow(exclude)) return children
        if (!policy) return null
        if (!allow(policy)) return null

        return children
      }}
    </Authenticated>
  )
})

export function withRestricted (Component, policy) {
  return React.memo(props => {
    return (
      <Restricted policy={policy}>
        <Component {...props} />
      </Restricted>
    )
  })
}

export default Restricted
