import React from 'react'
import {
  Dropdown as BSDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

function Menu (props) {
  return (
    <DropdownMenu
      {...props}
      positionFixed
      modifiers={{
        setMaxHeight: {
          enabled: true,
          fn: (data) => {
            return {
              ...data,
              styles: {
                ...data.styles,
                overflow: 'auto',
                maxHeight: '50%'
              }
            }
          }
        }
      }}
    />
  )
}

class Dropdown extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: props.isOpen }
    this.toggle = this.toggle.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  toggle () {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render () {
    const { isOpen } = this.state
    const { children, ...props } = this.props

    return (
      <BSDropdown {...props} toggle={this.toggle} isOpen={isOpen}>
        {children({
          Toggle: DropdownToggle,
          Menu: Menu,
          Item: DropdownItem,
          toggle: this.toggle
        })}
      </BSDropdown>
    )
  }
}

export default Dropdown
